var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Decoder } from "syncod";
import logger from 'App/logger';
import Resource, { TYPES } from 'Types/session/resource'; // MBTODO: player types?
import { TYPES as EVENT_TYPES } from 'Types/session/event';
import Log from 'Types/session/log';
import { update } from '../store';
import { init as initListsDepr, append as listAppend, setStartTime as setListsStartTime } from '../lists';
import StatedScreen from './StatedScreen/StatedScreen';
import ListWalker from './managers/ListWalker';
import PagesManager from './managers/PagesManager';
import MouseManager from './managers/MouseManager';
import PerformanceTrackManager from './managers/PerformanceTrackManager';
import WindowNodeCounter from './managers/WindowNodeCounter';
import ActivityManager from './managers/ActivityManager';
import AssistManager from './managers/AssistManager';
import MFileReader from './messages/MFileReader';
import { INITIAL_STATE as SUPER_INITIAL_STATE } from './StatedScreen/StatedScreen';
import { INITIAL_STATE as ASSIST_INITIAL_STATE } from './managers/AssistManager';
var LIST_NAMES = ["redux", "mobx", "vuex", "ngrx", "graphql", "exceptions", "profiles", "longtasks"];
var LISTS_INITIAL_STATE = {};
LIST_NAMES.forEach(function (name) {
    LISTS_INITIAL_STATE[name + "ListNow"] = [];
    LISTS_INITIAL_STATE[name + "List"] = [];
});
export var INITIAL_STATE = __assign(__assign(__assign(__assign({}, SUPER_INITIAL_STATE), LISTS_INITIAL_STATE), ASSIST_INITIAL_STATE), { performanceChartData: [], skipIntervals: [] });
function initLists() {
    var lists = {};
    for (var i = 0; i < LIST_NAMES.length; i++) {
        lists[LIST_NAMES[i]] = new ListWalker();
    }
    return lists;
}
var MessageDistributor = /** @class */ (function (_super) {
    __extends(MessageDistributor, _super);
    function MessageDistributor(session /*Session*/, jwt, config, live) {
        var _this = _super.call(this) || this;
        _this.session = session;
        // TODO: consistent with the other data-lists
        _this.locationEventManager = new ListWalker();
        _this.locationManager = new ListWalker();
        _this.loadedLocationManager = new ListWalker();
        _this.connectionInfoManger = new ListWalker();
        _this.performanceTrackManager = new PerformanceTrackManager();
        _this.windowNodeCounter = new WindowNodeCounter();
        _this.clickManager = new ListWalker();
        _this.resizeManager = new ListWalker([]);
        _this.scrollManager = new ListWalker();
        _this.decoder = new Decoder();
        _this.lists = initLists();
        _this.activirtManager = null;
        _this.navigationStartOffset = 0;
        _this.lastMessageTime = 0;
        /* Binded */
        _this.distributeMessage = function (msg, index) {
            var _a;
            if ([
                "mouse_move",
                "mouse_click",
                "create_element_node",
                "set_input_value",
                "set_input_checked",
                "set_viewport_size",
                "set_viewport_scroll",
            ].includes(msg.tp)) {
                (_a = _this.activirtManager) === null || _a === void 0 ? void 0 : _a.updateAcctivity(msg.time);
            }
            //const index = i + index; //?
            var decoded;
            var time = msg.time;
            switch (msg.tp) {
                /* Lists: */
                case "console_log":
                    if (msg.level === 'debug')
                        break;
                    listAppend("log", Log({
                        level: msg.level,
                        value: msg.value,
                        time: time,
                        index: index,
                    }));
                    break;
                case "fetch":
                    listAppend("fetch", Resource({
                        method: msg.method,
                        url: msg.url,
                        payload: msg.request,
                        response: msg.response,
                        status: msg.status,
                        duration: msg.duration,
                        type: TYPES.FETCH,
                        time: msg.timestamp - _this.sessionStart,
                        index: index,
                    }));
                    break;
                /* */
                case "set_page_location":
                    _this.locationManager.add(msg);
                    if (msg.navigationStart > 0) {
                        _this.loadedLocationManager.add(msg);
                    }
                    break;
                case "set_viewport_size":
                    _this.resizeManager.add(msg);
                    break;
                case "mouse_move":
                    _this.mouseManager.add(msg);
                    break;
                case "set_viewport_scroll":
                    _this.scrollManager.add(msg);
                    break;
                case "performance_track":
                    _this.performanceTrackManager.add(msg);
                    break;
                case "set_page_visibility":
                    _this.performanceTrackManager.handleVisibility(msg);
                    break;
                case "connection_information":
                    _this.connectionInfoManger.add(msg);
                    break;
                case "o_table":
                    _this.decoder.set(msg.key, msg.value);
                    break;
                case "redux":
                    decoded = _this._decodeMessage(msg, ["state", "action"]);
                    logger.log(decoded);
                    if (decoded != null) {
                        _this.lists.redux.add(decoded);
                    }
                    break;
                case "ng_rx":
                    decoded = _this._decodeMessage(msg, ["state", "action"]);
                    logger.log(decoded);
                    if (decoded != null) {
                        _this.lists.ngrx.add(decoded);
                    }
                    break;
                case "vuex":
                    decoded = _this._decodeMessage(msg, ["state", "mutation"]);
                    logger.log(decoded);
                    if (decoded != null) {
                        _this.lists.vuex.add(decoded);
                    }
                    break;
                case "mob_x":
                    decoded = _this._decodeMessage(msg, ["payload"]);
                    logger.log(decoded);
                    if (decoded != null) {
                        _this.lists.mobx.add(decoded);
                    }
                    break;
                case "graph_ql":
                    // @ts-ignore some hack? TODO: remove
                    msg.duration = 0;
                    _this.lists.graphql.add(msg);
                    break;
                case "profiler":
                    _this.lists.profiles.add(msg);
                    break;
                case "long_task":
                    _this.lists.longtasks.add(__assign(__assign({}, msg), { time: msg.timestamp - _this.sessionStart }));
                    break;
                default:
                    switch (msg.tp) {
                        case "create_document":
                            _this.windowNodeCounter.reset();
                            _this.performanceTrackManager.setCurrentNodesCount(_this.windowNodeCounter.count);
                            break;
                        case "create_text_node":
                        case "create_element_node":
                            _this.windowNodeCounter.addNode(msg.id, msg.parentID);
                            _this.performanceTrackManager.setCurrentNodesCount(_this.windowNodeCounter.count);
                            break;
                        case "move_node":
                            _this.windowNodeCounter.moveNode(msg.id, msg.parentID);
                            _this.performanceTrackManager.setCurrentNodesCount(_this.windowNodeCounter.count);
                            break;
                        case "remove_node":
                            _this.windowNodeCounter.removeNode(msg.id);
                            _this.performanceTrackManager.setCurrentNodesCount(_this.windowNodeCounter.count);
                            break;
                    }
                    _this.pagesManager.add(msg);
                    break;
            }
        };
        _this.pagesManager = new PagesManager(_this, _this.session.isMobile);
        _this.mouseManager = new MouseManager(_this);
        _this.assistManager = new AssistManager(session, _this, config);
        _this.sessionStart = _this.session.startedAt;
        if (live) {
            // const sockUrl = `wss://live.openreplay.com/1/${ this.session.siteId }/${ this.session.sessionId }/${ jwt }`;
            // this.subscribeOnMessages(sockUrl);
            initListsDepr({});
            _this.assistManager.connect();
        }
        else {
            _this.activirtManager = new ActivityManager(_this.session.duration.milliseconds);
            /* == REFACTOR_ME == */
            var eventList = _this.session.events.toJSON();
            initListsDepr({
                event: eventList,
                stack: _this.session.stackEvents.toJSON(),
                resource: _this.session.resources.toJSON(),
            });
            eventList.forEach(function (e) {
                if (e.type === EVENT_TYPES.LOCATION) { //TODO type system
                    _this.locationEventManager.add(e);
                }
                if (e.type === EVENT_TYPES.CLICK) {
                    _this.clickManager.add(e);
                }
            });
            _this.session.errors.forEach(function (e) {
                _this.lists.exceptions.add(e);
            });
            /* === */
            _this.loadMessages();
        }
        return _this;
    }
    // subscribeOnMessages(sockUrl) {
    //   this.setMessagesLoading(true);
    //   const socket = new WebSocket(sockUrl);
    //   socket.binaryType = 'arraybuffer';
    //   socket.onerror = (e) => {
    //     // TODO: reconnect
    //     update({ error: true });
    //   }
    //   socket.onmessage = (socketMessage) => {
    //     const data = new Uint8Array(socketMessage.data);
    //     const msgs = [];
    //     messageGenerator // parseBuffer(msgs, data);
    //     // TODO: count indexes. Now will not work due to wrong indexes
    //     //msgs.forEach(this.distributeMessage); 
    //     this.setMessagesLoading(false);
    //     this.setDisconnected(false);
    //   }
    //   this._socket = socket;
    // }
    MessageDistributor.prototype.loadMessages = function () {
        var _this = this;
        var fileUrl = this.session.mobsUrl;
        this.setMessagesLoading(true);
        window.fetch(fileUrl)
            .then(function (r) { return r.arrayBuffer(); })
            .then(function (b) {
            var _a, _b;
            var r = new MFileReader(new Uint8Array(b), _this.sessionStart);
            var msgs = [];
            while (r.hasNext()) {
                var next = r.next();
                if (next != null) {
                    _this.lastMessageTime = next[0].time;
                    _this.distributeMessage(next[0], next[1]);
                    msgs.push(next[0]);
                }
            }
            // @ts-ignore Hack for upet (TODO: fix ordering in one mutation (removes first))
            var headChildrenIds = msgs.filter(function (m) { return m.parentID === 1; }).map(function (m) { return m.id; });
            //const createNodeTypes = ["create_text_node", "create_element_node"];
            _this.pagesManager.sort(function (m1, m2) {
                if (m1.time === m2.time) {
                    if (m1.tp === "remove_node" && m2.tp !== "remove_node") {
                        if (headChildrenIds.includes(m1.id)) {
                            return -1;
                        }
                    }
                    else if (m2.tp === "remove_node" && m1.tp !== "remove_node") {
                        if (headChildrenIds.includes(m2.id)) {
                            return 1;
                        }
                    }
                    else if (m2.tp === "remove_node" && m1.tp === "remove_node") {
                        var m1FromHead = headChildrenIds.includes(m1.id);
                        var m2FromHead = headChildrenIds.includes(m2.id);
                        if (m1FromHead && !m2FromHead) {
                            return -1;
                        }
                        else if (m2FromHead && !m1FromHead) {
                            return 1;
                        }
                    }
                }
                return 0;
            });
            logger.info("Messages count: ", msgs.length, msgs);
            var stateToUpdate = {
                performanceChartData: _this.performanceTrackManager.chartData,
                performanceAvaliability: _this.performanceTrackManager.avaliability,
            };
            (_a = _this.activirtManager) === null || _a === void 0 ? void 0 : _a.end();
            stateToUpdate.skipIntervals = ((_b = _this.activirtManager) === null || _b === void 0 ? void 0 : _b.list) || [];
            LIST_NAMES.forEach(function (key) {
                stateToUpdate[key + "List"] = _this.lists[key].list;
            });
            update(stateToUpdate);
            _this.windowNodeCounter.reset();
            _this.setMessagesLoading(false);
        })
            .catch(function (e) {
            logger.error(e);
            _this.setMessagesLoading(false);
            update({ error: true });
        });
    };
    MessageDistributor.prototype.move = function (t, index) {
        var _this = this;
        var stateToUpdate = {};
        /* == REFACTOR_ME ==  */
        var lastLoadedLocationMsg = this.loadedLocationManager.moveToLast(t, index);
        if (!!lastLoadedLocationMsg) {
            setListsStartTime(lastLoadedLocationMsg.time);
            this.navigationStartOffset = lastLoadedLocationMsg.navigationStart - this.sessionStart;
        }
        var llEvent = this.locationEventManager.moveToLast(t, index);
        if (!!llEvent) {
            if (llEvent.domContentLoadedTime != null) {
                stateToUpdate.domContentLoadedTime = {
                    time: llEvent.domContentLoadedTime + this.navigationStartOffset,
                    value: llEvent.domContentLoadedTime,
                };
            }
            if (llEvent.loadTime != null) {
                stateToUpdate.loadTime = {
                    time: llEvent.loadTime + this.navigationStartOffset,
                    value: llEvent.loadTime,
                };
            }
            if (llEvent.domBuildingTime != null) {
                stateToUpdate.domBuildingTime = llEvent.domBuildingTime;
            }
        }
        /* === */
        var lastLocationMsg = this.locationManager.moveToLast(t, index);
        if (!!lastLocationMsg) {
            stateToUpdate.location = lastLocationMsg.url;
        }
        var lastConnectionInfoMsg = this.connectionInfoManger.moveToLast(t, index);
        if (!!lastConnectionInfoMsg) {
            stateToUpdate.connType = lastConnectionInfoMsg.type;
            stateToUpdate.connBandwidth = lastConnectionInfoMsg.downlink;
        }
        var lastPerformanceTrackMessage = this.performanceTrackManager.moveToLast(t, index);
        if (!!lastPerformanceTrackMessage) {
            stateToUpdate.performanceChartTime = lastPerformanceTrackMessage.time;
        }
        LIST_NAMES.forEach(function (key) {
            var lastMsg = _this.lists[key].moveToLast(t, key === 'exceptions' ? undefined : index);
            if (lastMsg != null) {
                stateToUpdate[key + "ListNow"] = _this.lists[key].listNow;
            }
        });
        update(stateToUpdate);
        /* Sequence of the managers is important here */
        // Preparing the size of "screen"
        var lastResize = this.resizeManager.moveToLast(t, index);
        if (!!lastResize) {
            this.setSize(lastResize);
        }
        this.pagesManager.moveReady(t).then(function () {
            var lastScroll = _this.scrollManager.moveToLast(t, index);
            if (!!lastScroll && _this.window) {
                _this.window.scrollTo(lastScroll.x, lastScroll.y);
            }
            // Moving mouse and setting :hover classes on ready view
            _this.mouseManager.move(t);
            var lastClick = _this.clickManager.moveToLast(t);
            // if (!!lastClick) {
            //   this.cursor.click();
            // }
            // After all changes - redraw the marker
            //this.marker.redraw();
        });
    };
    MessageDistributor.prototype._decodeMessage = function (msg, keys) {
        var _this = this;
        var decoded = {};
        try {
            keys.forEach(function (key) {
                decoded[key] = _this.decoder.decode(msg[key]);
            });
        }
        catch (e) {
            logger.error("Error on message decoding: ", e, msg);
            return null;
        }
        return __assign(__assign({}, msg), decoded);
    };
    MessageDistributor.prototype.getLastMessageTime = function () {
        return this.lastMessageTime;
    };
    MessageDistributor.prototype.getFirstMessageTime = function () {
        return 0; //this.pagesManager.minTime;
    };
    // TODO: clean managers?
    MessageDistributor.prototype.clean = function () {
        _super.prototype.clean.call(this);
        //if (this._socket) this._socket.close();
        update(INITIAL_STATE);
        this.assistManager.clear();
    };
    return MessageDistributor;
}(StatedScreen));
export default MessageDistributor;
