import store from 'App/store';
import { update, getState } from '../../store';
import MStreamReader from '../messages/MStreamReader';
;
import JSONRawMessageReader from '../messages/JSONRawMessageReader';
export var CallingState;
(function (CallingState) {
    CallingState[CallingState["Reconnecting"] = 0] = "Reconnecting";
    CallingState[CallingState["Requesting"] = 1] = "Requesting";
    CallingState[CallingState["True"] = 2] = "True";
    CallingState[CallingState["False"] = 3] = "False";
})(CallingState || (CallingState = {}));
;
export var ConnectionStatus;
(function (ConnectionStatus) {
    ConnectionStatus[ConnectionStatus["Connecting"] = 0] = "Connecting";
    ConnectionStatus[ConnectionStatus["WaitingMessages"] = 1] = "WaitingMessages";
    ConnectionStatus[ConnectionStatus["Connected"] = 2] = "Connected";
    ConnectionStatus[ConnectionStatus["Inactive"] = 3] = "Inactive";
    ConnectionStatus[ConnectionStatus["Disconnected"] = 4] = "Disconnected";
    ConnectionStatus[ConnectionStatus["Error"] = 5] = "Error";
})(ConnectionStatus || (ConnectionStatus = {}));
;
export function getStatusText(status) {
    switch (status) {
        case ConnectionStatus.Connecting:
            return "Connecting...";
        case ConnectionStatus.Connected:
            return "";
        case ConnectionStatus.Inactive:
            return "Client tab is inactive";
        case ConnectionStatus.Disconnected:
            return "Disconnected";
        case ConnectionStatus.Error:
            return "Something went wrong. Try to reload the page.";
        case ConnectionStatus.WaitingMessages:
            return "Connected. Waiting for the data... (The tab might be inactive)";
    }
}
export var INITIAL_STATE = {
    calling: CallingState.False,
    peerConnectionStatus: ConnectionStatus.Connecting,
    remoteControl: false,
};
var MAX_RECONNECTION_COUNT = 4;
var AssistManager = /** @class */ (function () {
    function AssistManager(session, md, config) {
        var _this = this;
        this.session = session;
        this.md = md;
        this.config = config;
        this.peer = null;
        this.connectionAttempts = 0;
        this.peeropened = false;
        this.initiateCallEnd = function () {
            _this.forceCallEnd();
            _this.notifyCallEnd();
            _this.localCallData && _this.localCallData.onCallEnd();
        };
        this.onTrackerCallEnd = function () {
            console.log('onTrackerCallEnd');
            _this.forceCallEnd();
            if (getState().calling === CallingState.Requesting) {
                _this.localCallData && _this.localCallData.onReject();
            }
            _this.localCallData && _this.localCallData.onCallEnd();
        };
        this.onCallDisconnect = function () {
            if (getState().calling === CallingState.True) {
                update({ calling: CallingState.Reconnecting });
            }
        };
        this.onMouseMove = function (e) {
            var data = _this.md.getInternalCoordinates(e);
            _this.send({ x: Math.round(data.x), y: Math.round(data.y) });
        };
        this.onWheel = function (e) {
            e.preventDefault();
            //throttling makes movements less smooth, so it is omitted
            //this.onMouseMove(e)
            _this.send({ type: "scroll", delta: [e.deltaX, e.deltaY] });
        };
        this.onMouseClick = function (e) {
            var conn = _this.dataConnection;
            if (!conn) {
                return;
            }
            var data = _this.md.getInternalCoordinates(e);
            // const el = this.md.getElementFromPoint(e); // requires requestiong node_id from domManager
            var el = _this.md.getElementFromInternalPoint(data);
            if (el instanceof HTMLElement) {
                el.focus();
                el.oninput = function (e) { return e.preventDefault(); };
                el.onkeydown = function (e) { return e.preventDefault(); };
            }
            conn.send({ type: "click", x: Math.round(data.x), y: Math.round(data.y) });
        };
        this.toggleRemoteControl = function (flag) {
            var state = getState().remoteControl;
            var newState = typeof flag === 'boolean' ? flag : !state;
            if (state === newState) {
                return;
            }
            if (newState) {
                _this.md.overlay.addEventListener("click", _this.onMouseClick);
                _this.md.overlay.addEventListener("wheel", _this.onWheel);
                update({ remoteControl: true });
            }
            else {
                _this.md.overlay.removeEventListener("click", _this.onMouseClick);
                _this.md.overlay.removeEventListener("wheel", _this.onWheel);
                update({ remoteControl: false });
            }
        };
        this.localCallData = null;
        this.closed = false;
    }
    AssistManager.prototype.setStatus = function (status) {
        if (getState().peerConnectionStatus === ConnectionStatus.Disconnected &&
            status !== ConnectionStatus.Connected) {
            return;
        }
        if (status === ConnectionStatus.Connecting) {
            this.md.setMessagesLoading(true);
        }
        else {
            this.md.setMessagesLoading(false);
        }
        if (status === ConnectionStatus.Connected) {
            this.md.display(true);
        }
        else {
            this.md.display(false);
        }
        update({ peerConnectionStatus: status });
    };
    Object.defineProperty(AssistManager.prototype, "peerID", {
        get: function () {
            return this.session.projectKey + "-" + this.session.sessionId;
        },
        enumerable: false,
        configurable: true
    });
    AssistManager.prototype.connect = function () {
        var _this = this;
        if (this.peer != null) {
            console.error("AssistManager: trying to connect more than once");
            return;
        }
        this.setStatus(ConnectionStatus.Connecting);
        // @ts-ignore
        var urlObject = new URL(window.ENV.API_EDP);
        import('peerjs').then(function (_a) {
            var Peer = _a.default;
            if (_this.closed) {
                return;
            }
            var _config = {
                host: urlObject.hostname,
                path: '/assist',
                port: urlObject.port === "" ? (location.protocol === 'https:' ? 443 : 80) : parseInt(urlObject.port),
            };
            if (_this.config) {
                _config['config'] = {
                    iceServers: _this.config,
                    sdpSemantics: 'unified-plan',
                    iceTransportPolicy: 'relay',
                };
            }
            var peer = new Peer(_config);
            _this.peer = peer;
            peer.on('error', function (e) {
                if (e.type !== 'peer-unavailable') {
                    console.warn("AssistManager PeerJS peer error: ", e.type, e);
                }
                if (['peer-unavailable', 'network', 'webrtc'].includes(e.type)) {
                    if (_this.peer) {
                        _this.setStatus(_this.connectionAttempts++ < MAX_RECONNECTION_COUNT
                            ? ConnectionStatus.Connecting
                            : ConnectionStatus.Disconnected);
                        _this.connectToPeer();
                    }
                }
                else {
                    console.error("PeerJS error (on peer). Type " + e.type, e);
                    _this.setStatus(ConnectionStatus.Error);
                }
            });
            peer.on("open", function () {
                if (_this.peeropened) {
                    return;
                }
                _this.peeropened = true;
                _this.connectToPeer();
            });
        });
    };
    AssistManager.prototype.connectToPeer = function () {
        var _this = this;
        if (!this.peer) {
            return;
        }
        this.setStatus(ConnectionStatus.Connecting);
        var id = this.peerID;
        var conn = this.peer.connect(id, { serialization: "json", reliable: true });
        conn.on('open', function () {
            window.addEventListener("beforeunload", function () { return conn.open && conn.send("unload"); });
            //console.log("peer connected")
            if (getState().calling === CallingState.Reconnecting) {
                _this._call();
            }
            var firstMessage = true;
            _this.setStatus(ConnectionStatus.WaitingMessages);
            var jmr = new JSONRawMessageReader();
            var reader = new MStreamReader(jmr);
            conn.on('data', function (data) {
                _this.disconnectTimeout && clearTimeout(_this.disconnectTimeout);
                if (Array.isArray(data)) {
                    jmr.append(data); // as RawMessage[]
                }
                else if (data instanceof ArrayBuffer) {
                    //rawMessageReader.append(new Uint8Array(data))
                }
                else {
                    return _this.handleCommand(data);
                }
                if (firstMessage) {
                    firstMessage = false;
                    _this.setStatus(ConnectionStatus.Connected);
                }
                for (var msg = reader.readNext(); msg !== null; msg = reader.readNext()) {
                    //@ts-ignore
                    _this.md.distributeMessage(msg, msg._index);
                }
            });
        });
        var onDataClose = function () {
            _this.onCallDisconnect();
            _this.connectToPeer();
        };
        conn.on('close', onDataClose); // What case does it work ?
        conn.on("error", function (e) {
            _this.setStatus(ConnectionStatus.Error);
        });
    };
    Object.defineProperty(AssistManager.prototype, "dataConnection", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.peer) === null || _a === void 0 ? void 0 : _a.connections[this.peerID]) === null || _b === void 0 ? void 0 : _b.find(function (c) { return c.type === 'data' && c.open; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AssistManager.prototype, "callConnection", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.peer) === null || _a === void 0 ? void 0 : _a.connections[this.peerID]) === null || _b === void 0 ? void 0 : _b.find(function (c) { return c.type === 'media' && c.open; });
        },
        enumerable: false,
        configurable: true
    });
    AssistManager.prototype.send = function (data) {
        var _a;
        (_a = this.dataConnection) === null || _a === void 0 ? void 0 : _a.send(data);
    };
    AssistManager.prototype.forceCallEnd = function () {
        var _a;
        (_a = this.callConnection) === null || _a === void 0 ? void 0 : _a.close();
    };
    AssistManager.prototype.notifyCallEnd = function () {
        var dataConn = this.dataConnection;
        if (dataConn) {
            dataConn.send("call_end");
        }
    };
    AssistManager.prototype.handleCommand = function (command) {
        var _this = this;
        console.log("Data command", command);
        switch (command) {
            case "unload":
                //this.onTrackerCallEnd();
                this.closeDataConnectionTimeout = setTimeout(function () {
                    var _a;
                    _this.onCallDisconnect();
                    (_a = _this.dataConnection) === null || _a === void 0 ? void 0 : _a.close();
                }, 1500);
                this.disconnectTimeout = setTimeout(function () {
                    _this.onTrackerCallEnd();
                    _this.setStatus(ConnectionStatus.Disconnected);
                }, 15000); // TODO: more convenient way
                return;
            case "call_end":
                this.onTrackerCallEnd();
                return;
            case "call_error":
                this.onTrackerCallEnd();
                this.setStatus(ConnectionStatus.Error);
                return;
        }
    };
    AssistManager.prototype.call = function (localStream, onStream, onCallEnd, onReject, onError) {
        var _this = this;
        this.localCallData = {
            localStream: localStream,
            onStream: onStream,
            onCallEnd: function () {
                onCallEnd();
                _this.toggleRemoteControl(false);
                _this.md.overlay.removeEventListener("mousemove", _this.onMouseMove);
                _this.md.overlay.removeEventListener("click", _this.onMouseClick);
                update({ calling: CallingState.False });
                _this.localCallData = null;
            },
            onReject: onReject,
            onError: onError,
        };
        this._call();
        return {
            end: this.initiateCallEnd,
            toggleRemoteControl: this.toggleRemoteControl,
        };
    };
    AssistManager.prototype._call = function () {
        var _this = this;
        if (!this.peer || !this.localCallData || ![CallingState.False, CallingState.Reconnecting].includes(getState().calling)) {
            return null;
        }
        update({ calling: CallingState.Requesting });
        //console.log('calling...', this.localCallData.localStream)
        var call = this.peer.call(this.peerID, this.localCallData.localStream.stream);
        this.localCallData.localStream.onVideoTrack(function (vTrack) {
            var sender = call.peerConnection.getSenders().find(function (s) { var _a; return ((_a = s.track) === null || _a === void 0 ? void 0 : _a.kind) === "video"; });
            if (!sender) {
                //logger.warn("No video sender found")
                return;
            }
            //logger.log("sender found:", sender)
            sender.replaceTrack(vTrack);
        });
        call.on('stream', function (stream) {
            update({ calling: CallingState.True });
            _this.localCallData && _this.localCallData.onStream(stream);
            _this.send({
                name: store.getState().getIn(['user', 'account', 'name']),
            });
            _this.md.overlay.addEventListener("mousemove", _this.onMouseMove);
            _this.md.overlay.addEventListener("click", _this.onMouseClick);
        });
        //call.peerConnection.addEventListener("track", e => console.log('newtrack',e.track))
        call.on("close", this.localCallData.onCallEnd);
        call.on("error", function (e) {
            console.error("PeerJS error (on call):", e);
            _this.initiateCallEnd();
            _this.localCallData && _this.localCallData.onError && _this.localCallData.onError();
        });
        window.addEventListener("beforeunload", this.initiateCallEnd);
    };
    AssistManager.prototype.clear = function () {
        this.closed = true;
        this.initiateCallEnd();
        if (this.peer) {
            console.log("destroying peer...");
            var peer = this.peer; // otherwise it calls reconnection on data chan close
            this.peer = null;
            peer.disconnect();
            peer.destroy();
        }
    };
    return AssistManager;
}());
export default AssistManager;
