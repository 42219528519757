import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchLiveList } from 'Duck/sessions';
import { Loader, NoContent } from 'UI';
import SessionItem from 'Shared/SessionItem';
function SessionList(props) {
    useEffect(function () {
        props.fetchLiveList();
    }, []);
    return (React.createElement(Loader, { loading: props.loading },
        React.createElement(NoContent, { show: !props.loading && (props.list.size === 0), title: "No live sessions." },
            React.createElement("div", { className: "p-4" }, props.list.map(function (session) { return React.createElement(SessionItem, { key: session.sessionId, session: session }); })))));
}
export default connect(function (state) {
    var session = state.getIn(['sessions', 'current']);
    return {
        session: session,
        list: state.getIn(['sessions', 'liveSessions'])
            .filter(function (i) { return i.userId === session.userId && i.sessionId !== session.sessionId; }),
        loading: state.getIn(['sessions', 'fetchLiveListRequest', 'loading']),
    };
}, { fetchLiveList: fetchLiveList })(SessionList);
