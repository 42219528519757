var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState } from 'react';
import { SlideModal, Icon } from 'UI';
import SessionList from '../SessionList';
import stl from './assistTabs.css';
var AssistTabs = function (props) {
    var _a = __read(useState(false), 2), showMenu = _a[0], setShowMenu = _a[1];
    return (React.createElement("div", { className: "relative mr-4" },
        React.createElement("div", { className: "flex items-center" },
            React.createElement("div", { className: stl.btnLink, onClick: function () { return setShowMenu(!showMenu); } }, "More Live Sessions"),
            React.createElement("span", { className: "mx-3 color-gray-medium" }, "by"),
            React.createElement("div", { className: "flex items-center" },
                React.createElement(Icon, { name: "user-alt", color: "gray-darkest" }),
                React.createElement("div", { className: "ml-2" }, props.userId))),
        React.createElement(SlideModal, { title: React.createElement("div", null,
                "Live Sessions by ",
                props.userId), isDisplayed: showMenu, content: showMenu && React.createElement(SessionList, null), onClose: function () { return setShowMenu(false); } })));
};
export default AssistTabs;
